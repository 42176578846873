export const SET_CURRENT_CHECKPOINT = 'SET_CURRENT_CHECKPOINT';
export const SET_CHECKPOINTS = 'SET_CHECKPOINTS';
export const SET_CHECKINS = 'SET_CHECKINS';
export const SAGA_FETCH_CHECKPOINT = 'SAGA_FETCH_CHECKPOINT';
export const SAGA_FETCH_CHECKPOINTS = 'SAGA_FETCH_CHECKPOINTS';
export const SAGA_CHECKIN = 'SAGA_CHECKIN';

// Store user info into local state.

export function setCurrentCheckpoint(checkpoint) {
    return {
        type: SET_CURRENT_CHECKPOINT,
        checkpoint,
    };
}

export function setCheckpoints(checkpoints) {
    return {
        type: SET_CHECKPOINTS,
        checkpoints,
    };
}

export function setCheckins(checkins) {
    return {
        type: SET_CHECKINS,
        checkins,
    };
}

export function fetchCheckpoint(cid) {
    return {
        type: SAGA_FETCH_CHECKPOINT,
        cid,
    };
}

export function fetchCheckpoints(installation) {
    return {
        type: SAGA_FETCH_CHECKPOINTS,
        installation,
    };
}

export function checkin(checkpoint) {
    return {
        type: SAGA_CHECKIN,
        checkpoint,
    };
}
