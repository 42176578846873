import {
    SET_USER_INFO,
    REGISTER_INIT_ACTION,
    UNREGISTER_INIT_ACTION,
    REGISTER_LOGIN_ACTION,
    UNREGISTER_LOGIN_ACTION,
} from '../state/user-actions';

export function user(state = null, action) {
    let newState;
    switch (action.type) {
    case (SET_USER_INFO):
        newState = null;
        if (action.userInfo) {
            newState = Object.assign({}, action.userInfo);
        }
        return newState;
    default:
        return state;
    }
}

export function initActions(state = [], action) {
    switch (action.type) {
    case (REGISTER_INIT_ACTION):
        return [...state, ...[action.action]];
    case (UNREGISTER_INIT_ACTION):
        return state.filter(a => a !== action.action);
    default:
        return state;
    }
}

export function loginActions(state = [], action) {
    switch (action.type) {
    case (REGISTER_LOGIN_ACTION):
        return [...state, ...[action.action]];
    case (UNREGISTER_LOGIN_ACTION):
        return state.filter(a => a !== action.action);
    default:
        return state;
    }
}
