import { takeLatest, put, select } from 'redux-saga/effects';
import { SAGA_FETCH_CHECKPOINT, SAGA_FETCH_CHECKPOINTS, SAGA_CHECKIN, setCurrentCheckpoint, setCheckins, fetchCheckpoints as actionFetchCheckpoints } from './checkpoint-actions';
import { setUIState } from './ui-actions';

function* workerFetchCheckpoint(action) {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET CHECKPOINTS

    const checkpoints = yield select(state => state.checkpoints);
    const checkpoint = checkpoints.find(cp => cp.id === action.cid);
    if (checkpoint) {
        checkpoint.qr = true;
        checkpoint.stay = 15;
        yield put(setCurrentCheckpoint(checkpoint));
    } else {
        yield put(setUIState('qrError', 'There was as error looking up the building and room for the posted QR code. Please try again or use manual checkin.'));
    }
}

export function* fetchCheckpoint() {
    yield takeLatest(SAGA_FETCH_CHECKPOINT, workerFetchCheckpoint);
}

function* workerFetchCheckpoints() {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET CHECKINS

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchCheckinsByUser&token=${user.token}&pool=us-east-1_0TTjV5HrZ`);
    const result = yield res.json();
    const { body } = result;
    const checkins = body.data;

    if (checkins) {
        yield put(setCheckins(checkins));
    }

    // GET CHECKPOINTS

    // let checkpoints = yield select(state => state.checkpoints);
    //
    // if (checkpoints.length === 0) {
    //     res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchCheckpoints&token=${user.token}&pool=us-east-1_0TTjV5HrZ&installation=${action.installation}`);
    //     result = yield res.json();
    //     body = result.body;
    //     checkpoints = body.data;
    //
    //     if (checkpoints) {
    //         yield put(setCheckpoints(checkpoints));
    //     }
    // }
}

export function* fetchCheckpoints() {
    yield takeLatest(SAGA_FETCH_CHECKPOINTS, workerFetchCheckpoints);
}

function* workerCheckin(action) {
    // GET INSTALLATION

    const installation = yield select(state => state.ui.installation);

    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET CHECKPOINT

    const { checkpoint } = action;
    checkpoint.area = checkpoint.area.toUpperCase();
    checkpoint.location = checkpoint.location.toUpperCase();
    checkpoint.installation = installation;

    // STORE

    yield put(setUIState('checkpointError', false));
    yield put(setUIState('checkpointSaving', true));
    try {
        yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=checkin&token=${user.token}&pool=us-east-1_0TTjV5HrZ&checkpoint=${encodeURI(JSON.stringify(checkpoint))}`);
    } catch (e) {
        yield put(setUIState('checkpointSaving', false));
        yield put(setUIState('checkpointError', true));
        return;
    }
    yield put(setUIState('checkpointError', false));
    yield put(setUIState('checkpointSaving', false));
    yield put(setCurrentCheckpoint(null));

    // REFRESH

    yield put(actionFetchCheckpoints(installation));
}

export function* checkin() {
    yield takeLatest(SAGA_CHECKIN, workerCheckin);
}
