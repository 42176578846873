import React, { useState } from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { useStyletron } from 'baseui';
import { Helmet } from 'react-helmet';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Header from '../controllers/header';

// Create styles
const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 20,
        paddingTop: 50,
    },
    pass: {
        minHeight: 100,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000000',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },
});

const PdfPass = ({ assessment, user }) => (
    <Document>
        <Page size="A4" style={pdfStyles.page}>
            <View style={pdfStyles.pass}>
                <View style={{
                    textAlign: 'center', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000000', paddingBottom: 15, paddingTop: 5,
                }}
                >
                    <Text style={{ fontWeight: 'bold' }}>Fisher Daily Pass</Text>
                    <Text style={{ fontSize: 34, color: '#82c341' }}>GO!</Text>
                    <Text style={{ fontSize: 28 }} render={() => (moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YYYY'))} />
                </View>

                <View style={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000000', padding: 25, paddingTop: 10,
                }}
                >
                    <View style={{ flex: 1 }}>
                        <View style={{ fontWeight: 'bold' }}><Text>{ user && user.userData.position !== 'STUDENT' && <Text>EMPLOYEE</Text>}{ user && user.userData.position === 'STUDENT' && <Text>STUDENT</Text>} NAME</Text></View>
                        <View style={{
                            fontWeight: 'normal',
                            textTransform: 'capitalize',
                        }}
                        ><Text style={{ fontSize: 18, textTransform: 'capitalize' }} render={() => (`${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`)} />
                        </View>
                    </View>

                    <View style={{ flex: 1 }}>
                        <View style={{ fontWeight: 'bold' }}><Text>DATE and TIME</Text></View>
                        <View style={{ fontWeight: 'normal' }}>
                            <Text style={{ fontSize: 18 }} render={() => (moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YYYY'))} />
                            <Text style={{ fontSize: 18, whiteSpace: 'nowrap' }} render={() => (moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('hh:mm A'))} />
                        </View>
                    </View>
                </View>

                <View style={{ paddingBottom: 20 }} >
                    <View style={{
                        fontSize: 16,
                        textAlign: 'center',
                        paddingTop: 10,
                    }}
                    >
                        <Text render={() => (`Reported on ${moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YYYY')}`)} />
                    </View>
                    <View style={{
                        marginTop: 20,
                        marginBottom: 20,
                        fontSize: 34,
                        textAlign: 'center',
                        color: '#82c341',
                    }}
                    >
                        <Text style={{ fontSize: 34, color: '#82c341' }}>Wear Your Mask</Text>
                        <Text style={{ fontSize: 34, color: '#82c341' }}>Wash Your Hands</Text>
                        <Text style={{ fontSize: 34, color: '#82c341' }}>Maintain 6 ft of Distance</Text>
                        <Text style={{ fontSize: 34, color: '#82c341' }}>Have A Great Day!</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>);

const ApprovedPass = ({ assessment, user }) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <div className={css({
            border: 'solid 1px #000000',
            borderRadius: '20px',
            minHeight: '100px',
        })}
        >
            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '22px' },
                })}
                >
                    Fisher Daily Pass
                </div>
                <div className={css({
                    fontSize: '54px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#6fcb2f',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '58px' },
                })}
                >
                    GO!
                </div>
                <div className={css({
                    fontSize: '58px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#6fcb2f',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '68px' },
                })}
                >
                    {moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YY')}
                </div>
                <div className={css({
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#6fcb2f',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '38px' },
                })}
                >
                    <span className={css({ whiteSpace: 'nowrap' })}>{moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('hh:mm A')}</span>
                </div>
            </div>

            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingTop: '20px',
                    justifyContent: 'center',
                    '@media screen and (min-width: 864px)': {
                        fontSize: '22px',
                        marginLeft: '80px',
                        marginRight: '80px',
                    },
                })}
                >
                    <div>
                        <div className={css({
                            fontWeight: 'normal',
                            textTransform: 'capitalize',
                            textAlign: 'center',
                            fontSize: '38px',
                        })}
                        >{user.firstName.toLowerCase()} {user.lastName.toLowerCase()}
                        </div>
                    </div>

                </div>
            </div>

            <div className={css({ paddingBottom: '25px' })} >
                <div className={css({
                    marginTop: '20px',
                    marginBottom: '20px',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#000000',
                    '@media screen and (min-width: 564px)': { fontSize: '48px' },
                })}
                >
                    Wear Your Mask, Wash Your Hands, Maintain 6 ft of Distance<br />Have a great day!
                </div>
            </div>
        </div>
    );
};

const DeniedPass = ({ assessment, user }) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <div className={css({
            border: 'solid 1px #000000',
            borderRadius: '20px',
            minHeight: '100px',
        })}
        >
            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '22px' },
                })}
                >
                    Fisher Daily Pass
                </div>
                <div className={css({
                    fontSize: '54px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#FF0000',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '58px' },
                })}
                >
                    STOP!
                </div>
                <div className={css({
                    fontSize: '58px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#FF0000',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '68px' },
                })}
                >
                    {moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YY')}
                </div>
                <div className={css({
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#FF0000',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '38px' },
                })}
                >
                    <span className={css({ whiteSpace: 'nowrap' })}>{moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('hh:mm A')}</span>
                </div>
            </div>

            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingTop: '20px',
                    justifyContent: 'center',
                    '@media screen and (min-width: 864px)': {
                        fontSize: '22px',
                        marginLeft: '80px',
                        marginRight: '80px',
                    },
                })}
                >
                    <div>
                        <div className={css({
                            fontWeight: 'normal',
                            textTransform: 'capitalize',
                            textAlign: 'center',
                            fontSize: '38px',
                        })}
                        >{user.firstName.toLowerCase()} {user.lastName.toLowerCase()}
                        </div>
                    </div>

                </div>
            </div>

            { user.role === 'STUDENT' && (
                <div className={css({ paddingBottom: '25px' })} >
                    <div className={css({
                        marginTop: '20px',
                        marginBottom: '20px',
                        fontSize: '22px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#000000',
                        '@media screen and (min-width: 564px)': { fontSize: '28px' },
                    })}
                    >
                        <p>You have a red pass either because you are beyond 7 days from your last COVID test or you have failed your health/travel screening.</p>
                        <p>For COVID testing questions, email <a href="residenttesting@sjfc.edu">residenttesting@sjfc.edu</a> or <a href="commutertesting@sjfc.edu">commutertesting@sjfc.edu</a> for instructions.</p>
                        <p>For health/travel screening questions, stay home/in your residence hall room and call the Health and Wellness Center at <a href="tel:585-385-8280">585-385-8280</a>, M-F 8:30am-4:30pm, or Safety and Security at <a href="tel:585-385-8025">585-385-8025</a> after hours and on the weekends for instructions.</p>
                    </div>
                </div>)}

            { user.role !== 'STUDENT' && (
                <div className={css({ paddingBottom: '25px' })} >
                    <div className={css({
                        marginTop: '20px',
                        marginBottom: '20px',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#000000',
                        '@media screen and (min-width: 564px)': { fontSize: '28px' },
                    })}
                    >
                        <p>Do not come to campus.<br />Call your supervisor to report your absence. Visit <a href="https://www.sjfc.edu/services/human-resources/">our HR Link</a> for further instruction. Contact your health care provider as necessary.</p>
                    </div>
                </div>)}
        </div>
    );
};

const NotAttendingPass = ({ assessment, user }) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <div className={css({
            border: 'solid 1px #000000',
            borderRadius: '20px',
            minHeight: '100px',
        })}
        >
            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    fontSize: '16px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '22px' },
                })}
                >
                    Fisher Daily Pass
                </div>
                <div className={css({
                    fontSize: '54px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#0000FF',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '58px' },
                })}
                >
                    Thanks!
                </div>
                <div className={css({
                    fontSize: '58px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#0000FF',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '68px' },
                })}
                >
                    {moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('MM/DD/YY')}
                </div>
                <div className={css({
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#0000FF',
                    marginTop: '10px',
                    '@media screen and (min-width: 564px)': { fontSize: '38px' },
                })}
                >
                    <span className={css({ whiteSpace: 'nowrap' })}>{moment.unix(typeof (assessment.created.seconds) !== 'undefined' ? assessment.created.seconds : assessment.created).format('hh:mm A')}</span>
                </div>
            </div>

            <div className={css({
                paddingBottom: '25px',
                borderBottom: 'solid 1px #000000',
            })}
            >
                <div className={css({
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingTop: '20px',
                    justifyContent: 'center',
                    '@media screen and (min-width: 864px)': {
                        fontSize: '22px',
                        marginLeft: '80px',
                        marginRight: '80px',
                    },
                })}
                >
                    <div>
                        <div className={css({
                            fontWeight: 'normal',
                            textTransform: 'capitalize',
                            textAlign: 'center',
                            fontSize: '38px',
                        })}
                        >{user.firstName.toLowerCase()} {user.lastName.toLowerCase()}
                        </div>
                    </div>

                </div>
            </div>

            { user.role === 'STUDENT' && (
                <div className={css({ paddingBottom: '25px' })} >
                    <div className={css({
                        marginTop: '20px',
                        marginBottom: '20px',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#0000FF',
                        '@media screen and (min-width: 564px)': { fontSize: '28px' },
                    })}
                    >
                        <p>You aren’t coming to campus today. If that changes you will need to update your Daily Pass.</p>
                    </div>
                </div>)}

            { user.role !== 'STUDENT' && (
                <div className={css({ paddingBottom: '25px' })} >
                    <div className={css({
                        marginTop: '20px',
                        marginBottom: '20px',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#0000FF',
                        '@media screen and (min-width: 564px)': { fontSize: '28px' },
                    })}
                    >
                        <p>You aren’t coming to campus today. If that changes you will need to update your Daily Pass.</p>
                    </div>
                </div>)}
        </div>
    );
};

const Pass = ({
    user,
    assessment,
    expired,
}) => {
    const [showPdfDownload, setShowPdfDownload] = useState();

    // STYLES

    const [css] = useStyletron();

    if (user === null) {
        return <div />;
    }

    return (
        <PrintProvider>
            <NoPrint>
                <Helmet>
                    <title>SJFC Self Assessment</title>
                </Helmet>

                <Header />

                <div className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '5%',
                    marginRight: '5%',
                    marginTop: '5%',
                    '@media screen and (min-width: 564px)': { marginLeft: '20%', marginRight: '20%' },
                })}
                >
                    { ((assessment === null || expired === true)) && (
                        <div className={css({
                            border: 'solid 1px #000000',
                            borderRadius: '20px',
                            minHeight: '100px',
                        })}
                        >
                            <div className={css({ marginBottom: '20px', textAlign: 'center' })} >
                                <p>Please complete your self assessment.</p>
                                <Link
                                    className={css({
                                        textDecoration: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '12px', padding: '8px', fontSize: '18px',
                                    })}
                                    to="/"
                                >Let&apos;s Start
                                </Link>
                            </div>
                        </div>)}

                    { (assessment && assessment.status === 'APPROVED' && expired === false) && (
                        <div>
                            <Print>
                                <ApprovedPass assessment={assessment} user={user} />
                            </Print>
                        </div>
                    )}

                    { (assessment && assessment.status === 'APPROVED' && expired === false) && (
                        <div className={css({ marginTop: '15px' })}>
                            <div className={css({ textAlign: 'center', marginBottom: '20px' })}>
                                { (assessment && user && !showPdfDownload) && (
                                    <button
                                        style={{
                                            whiteSpace: 'nowrap', textDecoration: 'none', marginLeft: '15px', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '12px', padding: '6px', fontSize: '20px',
                                        }}
                                        onClick={() => setShowPdfDownload(true)}
                                    >Optional: Download Screen
                                    </button>)}

                                { (assessment && user && showPdfDownload) && (
                                    <PDFDownloadLink
                                        style={{
                                            whiteSpace: 'nowrap', textDecoration: 'none', marginLeft: '15px', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '12px', padding: '6px', fontSize: '20px',
                                        }}
                                        document={<PdfPass assessment={assessment} user={user} />}
                                        fileName={`DailyPass-${moment().format('MM-DD-YYYY')}.pdf`}
                                    >
                                        {({
                                            loading,
                                        }) => (loading ? 'Loading document...' : 'PDF Ready... Download Now')}
                                    </PDFDownloadLink>)}
                            </div>

                            <div className={css({ display: 'none', textAlign: 'center', marginTop: '10px' })}>
                                <button
                                    className={css({
                                        border: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '12px', padding: '8px', fontSize: '18px',
                                    })}
                                    onClick={() => { window.print(); }}
                                >PRINT
                                </button>
                            </div>
                        </div>
                    )}

                    { (assessment && assessment.status === 'DENIED' && expired === false) && (
                        <DeniedPass assessment={assessment} user={user} />
                    )}

                    { (assessment && assessment.status === 'NOT_ATTENDING') && (
                        <NotAttendingPass assessment={assessment} user={user} />
                    )}

                    <div className={css({ height: '75px' })} />

                </div>
            </NoPrint>
        </PrintProvider>
    );
};

export default Pass;
