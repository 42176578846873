import React from 'react';
import { useStyletron } from 'baseui';
import { Helmet } from 'react-helmet';
import Header from '../controllers/header';
import YesNo from '../components/yessno';

const Questions = ({
    user, denied, onLogin, assessment, setAssessmentValue, onSubmit, logError, logSaving,
}) => {
    // STYLES

    const [css] = useStyletron();

    if (!user) {
        return (
            <div className={css({ margin: '10%', textAlign: 'center' })}>
                <p>You are not currently logged in. You must log in as a valid and authorized user to proceed.</p>
                <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
            </div>
        );
    }

    if (denied) {
        return (
            <>
                <Helmet>
                    <title>SJFC Daily Health Screen</title>
                </Helmet>

                <Header />

                <div className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '5%',
                    marginRight: '5%',
                    marginTop: '0',
                    '@media screen and (min-width: 564px)': {
                        marginLeft: '20%',
                        marginRight: '20%',
                    },
                })}
                >
                    <h2 className={css({ textTransform: 'uppercase' })}>Self Assessment</h2>
                    <p className={css({ fontWeight: 'bold' })}>
                        You have been instructed not to come to campus or, if you are a residential student, do not leave your residence hall room based on the health screening you submitted. Contact the Health and Wellness Center at 585-385-8280, 8:30am-4:30pm, M-F. After Hours and Weekends: Contact the Office of Safety and Security, 585-385-8025. An approved health screen is required to circulate on campus.
                    </p>
                    <p className={css({ textAlign: 'center' })}>
                        <a href="/pass">VIEW MY HEALTH SCREEN</a>
                    </p>
                </div>
            </>
        );
    }

    const allOriginalAnswered = () => (
        (typeof (assessment.q13) !== 'undefined') &&
        (typeof (assessment.q14) !== 'undefined' || assessment.q16 === 'yes') &&
        (typeof (assessment.q15) !== 'undefined')
    );

    const allAnswered = () => allOriginalAnswered();

    return (
        <>
            <Helmet>
                <title>SJFC Daily Health Screen</title>
            </Helmet>

            <Header />

            <div className={css({
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '5%',
                marginRight: '5%',
                marginTop: '0',
                '@media screen and (min-width: 564px)': {
                    marginLeft: '20%',
                    marginRight: '20%',
                },
            })}
            >
                { assessment.q0 === 'yes' && (
                    <p className={css({ fontSize: '20px' })}>
                        <strong>Please answer the following questions.</strong>
                    </p>)}

                { typeof (assessment.q0) === 'undefined' && (
                    <div className={css({
                        marginTop: '100px', flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '45px', minHeight: '50px',
                    })}
                    >
                        <div className={css({
                            flex: '2',
                            fontSize: '16px',
                            marginRight: '30px',
                            '@media screen and (min-width: 564px)': {
                                fontSize: '22px',
                            },
                        })}
                        >
                            Are you spending any time on campus today?
                        </div>

                        <div className={css({ flex: '1', marginTop: '15px' })}>
                            <YesNo value={assessment.q0} onChange={value => setAssessmentValue(assessment, 'q0', value)} />
                        </div>

                        { logSaving !== true && (
                            <div css={css({ width: '80%' })}>
                                { logError && <p className={css({ color: '#ff0000' })}>WARNING: You are either not logged in or have lost network connection. Retry or Login...</p>}

                                { logError && (
                                    <button
                                        className={css({
                                            marginRight: '20px', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                        })}
                                        onClick={() => onLogin()}
                                    >Login
                                    </button>)}
                            </div>)}
                    </div>)}

                { typeof (assessment.q0) !== 'undefined' && (
                    <div className={css({
                        marginTop: '100px', flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '45px', minHeight: '50px',
                    })}
                    >
                        <div className={css({
                            flex: '2',
                            fontSize: '16px',
                            marginRight: '30px',
                            '@media screen and (min-width: 564px)': {
                                fontSize: '22px',
                            },
                        })}
                        >
                            Are you fully vaccinated? People are considered fully vaccinated if at least 2 weeks have passed since their second dose of a two-dose series (Pfizer or Moderna) or 2 weeks have passed since their single-dose vaccine (Johnson & Johnson).
                        </div>

                        <div className={css({ flex: '1', marginTop: '15px' })}>
                            <YesNo value={assessment.q16} onChange={value => setAssessmentValue(assessment, 'q16', value)} />
                        </div>

                        { logSaving !== true && (
                            <div css={css({ width: '80%' })}>
                                { logError && <p className={css({ color: '#ff0000' })}>WARNING: You are either not logged in or have lost network connection. Retry or Login...</p>}

                                { logError && (
                                    <button
                                        className={css({
                                            marginRight: '20px', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                        })}
                                        onClick={() => onLogin()}
                                    >Login
                                    </button>)}
                            </div>)}
                    </div>)}

                { assessment.q0 === 'yes' && assessment.q16 === 'no' && (
                    <div>
                        <div className={css({
                            flex: '1', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', marginBottom: '45px', minHeight: '50px',
                        })}
                        >
                            <div className={css({
                                flex: '2',
                                fontSize: '16px',
                                marginRight: '30px',
                                '@media screen and (min-width: 564px)': {
                                    fontSize: '22px',
                                },
                            })}
                            >
                                1.    Have you had any of the following symptoms in the last 24 hours that are <u>new or not usual</u> for you or can’t be explained by a recent vaccination (vaccine side effects may include chills,fever, nausea, fatigue, muscle aches, headache)?
                                <ul>
                                    <li>Loss of taste or smell</li>
                                    <li>Chills or fever of 100 F (37.8 C) or higher</li>
                                    <li>New cough or change in your cough</li>
                                    <li>New or worsening shortness of breath (difficulty breathing)</li>
                                    <li>Abdominal pain, nausea, vomiting, diarrhea or loss of appetite</li>
                                    <li>Congestion or runny nose not due to allergies</li>
                                    <li>Sore throat (not due to allergies)</li>
                                    <li>Fatigue and/or muscle aches</li>
                                    <li>Unusual headache or eye pain</li>
                                </ul>
                            </div>
                            <div className={css({ flex: '1', marginTop: '15px' })}>
                                <div className={css({
                                    height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
                                })}
                                >
                                    <YesNo value={assessment.q13} onChange={value => setAssessmentValue(assessment, 'q13', value)} />
                                </div>
                            </div>
                        </div>
                        <div className={css({
                            flex: '1', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', marginBottom: '45px', minHeight: '50px',
                        })}
                        >
                            <div className={css({
                                flex: '2',
                                fontSize: '16px',
                                marginRight: '30px',
                                '@media screen and (min-width: 564px)': {
                                    fontSize: '22px',
                                },
                            })}
                            >
                                2.     In the last 10 days, have you knowingly been in close contact with anyone who has tested positive for COVID-19 (except in your work as a health care worker or student nurse), and/or are you waiting for a COVID-19 test result or have you tested positive for COVID-19?
                            </div>
                            <div className={css({ flex: '1', marginTop: '15px' })}>
                                <YesNo value={assessment.q14} onChange={value => setAssessmentValue(assessment, 'q14', value)} />
                            </div>
                        </div>
                        <div className={css({
                            flex: '1', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px',
                        })}
                        >
                            <div className={css({
                                flex: '2',
                                fontSize: '16px',
                                marginRight: '30px',
                                '@media screen and (min-width: 564px)': {
                                    fontSize: '22px',
                                },
                            })}
                            >
                                3.    Have you travelled internationally and not followed CDC guidelines or have you travelled domestically and not followed the College’s current travel policies?
                            </div>
                            <div className={css({ flex: '1', marginTop: '15px' })}>
                                <YesNo value={assessment.q15} onChange={value => setAssessmentValue(assessment, 'q15', value)} />
                            </div>
                        </div>
                    </div>)}

                { assessment.q0 === 'yes' && assessment.q16 === 'yes' && (
                    <div>
                        <div className={css({
                            flex: '1', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', marginBottom: '45px', minHeight: '50px',
                        })}
                        >
                            <div className={css({
                                flex: '2',
                                fontSize: '16px',
                                marginRight: '30px',
                                '@media screen and (min-width: 564px)': {
                                    fontSize: '22px',
                                },
                            })}
                            >
                                1.    Have you had any of the following symptoms in the last 24 hours that are <u>new or not usual</u> for you?
                                <ul>
                                    <li>Loss of taste or smell</li>
                                    <li>Chills or fever of 100 F (37.8 C) or higher</li>
                                    <li>New cough or change in your cough</li>
                                    <li>New or worsening shortness of breath (difficulty breathing)</li>
                                    <li>Abdominal pain, nausea, vomiting, diarrhea or loss of appetite</li>
                                    <li>Congestion or runny nose not due to allergies</li>
                                    <li>Sore throat (not due to allergies)</li>
                                    <li>Fatigue and/or muscle aches</li>
                                    <li>Unusual headache or eye pain</li>
                                </ul>
                            </div>
                            <div className={css({ flex: '1', marginTop: '15px' })}>
                                <div className={css({
                                    height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
                                })}
                                >
                                    <YesNo value={assessment.q13} onChange={value => setAssessmentValue(assessment, 'q13', value)} />
                                </div>
                            </div>
                        </div>
                        <div className={css({
                            flex: '1', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', marginBottom: '35px', minHeight: '50px',
                        })}
                        >
                            <div className={css({
                                flex: '2',
                                fontSize: '16px',
                                marginRight: '30px',
                                '@media screen and (min-width: 564px)': {
                                    fontSize: '22px',
                                },
                            })}
                            >
                                2.    Have you travelled internationally and NOT followed the necessary <a href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/map-and-travel-notices.html" target="_blank" rel="noopener noreferrer">CDC guidance</a> for returning to the United States?
                            </div>
                            <div className={css({ flex: '1', marginTop: '15px' })}>
                                <YesNo value={assessment.q15} onChange={value => setAssessmentValue(assessment, 'q15', value)} />
                            </div>
                        </div>
                    </div>)}

                <div className={css({ height: '80px' })}>
                    {allAnswered() && (
                        <div>
                            { logSaving !== true && (
                                <>
                                    { logError && <p className={css({ color: '#ff0000' })}>WARNING: You are either not logged in or have lost network connection. Retry or Login...</p>}

                                    { logError && (
                                        <button
                                            className={css({
                                                marginRight: '20px', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                            })}
                                            onClick={() => onLogin()}
                                        >Login
                                        </button>)}

                                    <button
                                        className={css({
                                            cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                        })}
                                        onClick={() => onSubmit(assessment)}
                                    >{ logError ? 'Retry' : 'Submit' }
                                    </button>
                                </>)}
                        </div>
                    )}
                </div>
            </div>
            <div className={css({ height: '75px' })} />
        </>
    );
};

export default Questions;
