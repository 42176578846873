import React from 'react';
import { StatefulCalendar } from 'baseui/datepicker';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useStyletron } from 'baseui';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../controllers/header';

const Home = ({
    user, onLogin, loggingIn, onSetDates, reportDates, onFetchReport, fetchingReport, csvDownloadReady, onDownloadReport, assessments, appMessage, onSetStudentFile, studentCSVFile, onUploadStudentCSV, showCheckIn, hasPass, loginError,
}) => {
    // STYLES

    const [css] = useStyletron();

    return (
        <>
            <Helmet>
                <title>SJFC Daily Health Screen</title>
            </Helmet>

            <Header />

            <div className={css({ display: 'flex', justifyContent: 'center', flexDirection: 'column' })}>

                {(appMessage === true) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })} />
                )}

                {(user === null && loggingIn === false && appMessage === false && loginError === false) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>You are not currently logged in. You must log in as a valid and authorized user to proceed.</p>
                        <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
                    </div>
                )}

                {(user === null && loggingIn === false && appMessage === false && loginError !== false) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>{loginError}</p>
                        <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
                    </div>
                )}

                {(user === null && loggingIn === true && appMessage === false) && (
                    <div className={css({ margin: '10%', textAlign: 'center' })}>
                        <p>Loading... please wait.</p>
                    </div>
                )}

                {(user && appMessage === false) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        <h2>COMPLETE YOUR SELF ASSESSMENT</h2>
                        <div className={css({ marginBottom: '20px', textAlign: 'left' })} >

                            <p className={css({ textAlign: 'left' })}>Given the COVID-19 pandemic, all members of the SJFC Community are required to complete the Daily Health Screen to monitor the health of our community. If you are advised not to come to campus, please stay home.</p>

                            <Link
                                className={css({
                                    minWidth: '190px', display: 'inline-block', textAlign: 'center', textDecoration: 'none', cursor: 'pointer', backgroundColor: '#993333', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                })}
                                to="/assessment"
                            >Start Assessment
                            </Link>
                        </div>

                        { (showCheckIn && hasPass === false) && (
                            <div className={css({
                                display: 'flex', marginTop: '30px;', marginLeft: '30px', marginRight: '30px', border: 'solid 1px #000000', borderRadius: '8px', padding: '15px',
                            })}
                            >
                                <div><i className={`${css({ color: '#f76902', fontSize: '100px' })} fas fa-exclamation-triangle`} /></div>
                                <div className={css({
                                    fontWeight: 'bold', fontSize: '22px', padding: '15px', paddingTop: '30px',
                                })}
                                >You must complete a <Link to="/assessment">self assessment</Link> before checking into a building  and room on campus.
                                </div>
                            </div>
                        )}

                        { (showCheckIn && hasPass === true) && (
                            <div className={css({ marginTop: '30px' })}>
                                <Link
                                    className={css({
                                        minWidth: '190px', display: 'inline-block', textAlign: 'center', textDecoration: 'none', cursor: 'pointer', backgroundColor: '#993333', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                    })}
                                    to="/checkin"
                                >Location Check In
                                </Link>
                            </div>
                        )}

                        { (user && user.adminPass) && (
                            <div className={css({ marginTop: '30px' })}>
                                <Link
                                    className={css({
                                        minWidth: '190px', display: 'inline-block', textAlign: 'center', textDecoration: 'none', cursor: 'pointer', backgroundColor: '#993333', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                    })}
                                    to="/adminpass"
                                >Admin User Passes
                                </Link>
                            </div>
                        )}
                    </div>
                )}

                {(user && appMessage === false && (user.userData.role === 'ADMIN')) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        <h2>Assessment Log</h2>
                        <p>Please choose a date range.</p>
                        <div className={css({ display: 'flex', flexWrap: 'wrap' })}>
                            <div className={css({ display: 'flex' })}>
                                <StatefulCalendar
                                    monthsShown={2}
                                    range
                                    onChange={({ date }) =>
                                        onSetDates(date)
                                    }
                                />
                            </div>
                            <div className={css({ marginLeft: '15px' })}>
                                { reportDates.length >= 2 && (
                                    <div>
                                        { (fetchingReport === false && csvDownloadReady === false) && (
                                            <button
                                                className={css({
                                                    cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                                })}
                                                onClick={onFetchReport}
                                            >Fetch data for periods {moment(reportDates[0]).format('MM/DD/YYYY')} - {moment(reportDates[1]).format('MM/DD/YYYY')}
                                            </button>)}

                                        { fetchingReport === true && (
                                            <span>Fetching Report Data...</span>
                                        )}

                                        { csvDownloadReady === true && (
                                            <div>
                                                <CSVLink
                                                    className={css({
                                                        textDecoration: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                                    })}
                                                    data={assessments}
                                                    filename={`assessments${moment(reportDates[0]).format('MM/DD/YYYY')}-${moment(reportDates[1]).format('MM/DD/YYYY')}.csv`}
                                                    onClick={onDownloadReport}
                                                >CSV File Ready... Download
                                                </CSVLink>

                                                <button
                                                    className={css({
                                                        position: 'relative', top: '-2px', display: 'inline-block', border: 'none', height: '40px', marginLeft: '15px;', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                                    })}
                                                    onClick={onDownloadReport}
                                                >Clear
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <h2>Upload Student List CSV</h2>
                        <input type="file" onChange={e => onSetStudentFile(e.target.files[0])} />
                        { studentCSVFile && (
                            <button
                                className={css({
                                    position: 'relative', top: '-2px', display: 'inline-block', border: 'none', height: '40px', marginLeft: '15px;', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                                })}
                                onClick={onUploadStudentCSV}
                            >Upload
                            </button>)}
                    </div>
                )}
                {(user && appMessage === false && (user.userData.role === 'ADMIN' || user.userData.role === 'REPORTER')) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        <Link
                            className={css({
                                textDecoration: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                            })}
                            to="/student-report"
                        >View Student Report
                        </Link>
                    </div>
                )}

                {(user && appMessage === false && (user.userData.isSupervisor === true)) && (
                    <div className={css({
                        marginLeft: '10%', marginRight: '10%', marginTop: '60px', textAlign: 'left',
                    })}
                    >
                        <Link
                            className={css({
                                textDecoration: 'none', cursor: 'pointer', backgroundColor: '#acd18c', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                            })}
                            to="/report"
                        >View Assessments By Supervisor
                        </Link>
                    </div>
                )}
            </div>

            <div className={css({ height: '75px' })} />
        </>
    );
};

export default Home;
