import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { push } from 'connected-react-router';
import CheckIn from '../components/checkin';
import { setUIState } from '../state/ui-actions';
import { setCurrentCheckpoint, checkin } from '../state/checkpoint-actions';

const mapStateToProps = (state) => {
    let hasPass = true; // TOGGLE TO FALSE TO LIMIT CHECKINS OT HAVING PASS
    let passDate;
    if (state.assessment) {
        hasPass = true;
        passDate = moment.unix(typeof (state.assessment.created.seconds) !== 'undefined' ? state.assessment.created.seconds : state.assessment.created);
        const now = moment();
        const diff = now.diff(passDate, 'hours');
        if (diff > 24) {
            hasPass = true; // TOGGLE TO FALSE TO LIMIT CHECKINS OT HAVING PASS
        }
    }

    // BUILDINGS

    const buildings = _.uniqBy(state.checkpoints, 'buildingId');
    const building = typeof (state.ui.building) !== 'undefined' ? state.ui.building : null;
    let rooms = state.checkpoints.filter(cp => (building ? cp.buildingId === building.buildingId : false));
    rooms = [...[{
        id: '', buildingId: '', buildingName: '', room: 'Select A Room...',
    }], ...rooms];

    return {
        user: state.user ? state.user : null,
        hasPass,
        qrError: typeof (state.ui.qrError) !== 'undefined' ? state.ui.qrError : false,
        qrCheckIn: typeof (state.ui.qrCheckIn) !== 'undefined' ? state.ui.qrCheckIn : false,
        manualCheckIn: typeof (state.ui.manualCheckIn) !== 'undefined' ? state.ui.manualCheckIn : false,
        checkpoint: state.checkpoint,
        checkpoints: state.checkpoints,
        buildings,
        building,
        rooms,
        checkins: state.checkins,
    };
};

const mapDispatchToProps = dispatch => ({
    onLogin: () => {
        window.location.href = '/login.html';
    },
    onQRCheckin: () => {
        dispatch(setCurrentCheckpoint(null));
        dispatch(setUIState('qrError', null));
        dispatch(setUIState('qrCheckIn', true));
        dispatch(setUIState('manualCheckIn', false));
    },
    onManualCheckIn: () => {
        dispatch(setCurrentCheckpoint(null));
        dispatch(setUIState('qrError', null));
        dispatch(setUIState('manualCheckIn', true));
        dispatch(setUIState('qrCheckIn', false));
    },
    onQRError: (err) => {
        console.log('QR Error', err);
    },
    onQRScan: (data) => {
        if (data) {
            dispatch(setUIState('qrCheckIn', false));
            dispatch(setUIState('manualCheckIn', false));
            const parts = data.split('/');

            if (parts.length === 6 && parts[3] === 'checkin' && parts[4].trim() !== '' && parts[5].trim() !== '') {
                dispatch(setCurrentCheckpoint({ area: parts[4], location: parts[5], qr: true }));
            } else {
                dispatch(setUIState('qrError', 'There was an error in the QR Code format. Please retry or scan a different QR Code.'));
            }
        }
    },
    onBuildingChanged: (data) => {
        dispatch(setCurrentCheckpoint(null));
        dispatch(setUIState('building', data));
    },
    onRoomChanged: (_data) => {
        const data = _data;
        data.stay = 15;
        dispatch(setCurrentCheckpoint(data));
    },
    onStayChanged: (inc, _checkpoint) => {
        const checkpoint = _checkpoint;
        if (typeof (checkpoint.stay) === 'undefined') {
            checkpoint.stay = 15;
        }
        checkpoint.stay += inc;
        if (checkpoint.stay < 15) {
            checkpoint.stay = 15;
        }
        dispatch(setCurrentCheckpoint(checkpoint));
    },
    onCancel: () => {
        dispatch(setCurrentCheckpoint(null));
        dispatch(setUIState('qrError', null));
        dispatch(setUIState('qrCheckIn', false));
        dispatch(setUIState('manualCheckIn', false));
        dispatch(push('/'));
    },
    onSubmit: (checkpoint) => {
        dispatch(checkin(checkpoint));
        dispatch(setUIState('qrCheckIn', false));
        dispatch(setUIState('manualCheckIn', false));
    },
});

const CheckInController = connect(mapStateToProps, mapDispatchToProps)(CheckIn);

export default CheckInController;
