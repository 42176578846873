import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE,
} from 'baseui/modal';
import { Input } from 'baseui/input';
import { KIND as ButtonKind } from 'baseui/button';

const Ask = ({
    title, children, isOpen, onCancel, onAnswer, error, okText, showInput, type = 'text',
}) => {
    const [value, setValue] = useState('');
    return (
        <Modal
            onClose={onCancel}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
            unstable_ModalBackdropScroll
            overrides={{
                Dialog: {
                    style: () => ({
                        marginTop: '10px',
                        marginBottom: '10px',
                        marginLeft: '10px',
                        marginRight: '10px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                    }),
                },
                Root: { style: () => ({ zIndex: '105' }) },
            }}
        >
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                {children}
                { showInput !== false && (<Input
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onKeyDown={(e) => { if (e.nativeEvent.keyCode === 13) { onAnswer(value); e.preventDefault(); } }}
                    clearOnEscape
                    type={type}
                />)}
                { error && <p>{error}</p>}
            </ModalBody>
            <ModalFooter>
                <ModalButton kind={ButtonKind.tertiary} onClick={onCancel}>
                    Cancel
                </ModalButton>
                <ModalButton onClick={() => onAnswer(value)}>{okText || 'Ok'}</ModalButton>
            </ModalFooter>
        </Modal>
    );
};

export default Ask;
