import React from 'react';
import { Route, Switch } from 'react-router';
import { useStyletron } from 'baseui';
import UpdateAppController from './controllers/update-app';
import HomeController from './controllers/home';
import QuestionsController from './controllers/questions';
import PassController from './controllers/pass';
import StudentReport from './controllers/student-report';
import Report from './controllers/report';
import CheckInController from './controllers/checkin';
import AdminPassController from './controllers/adminPass';
import './App.css';

const App = () => {
    const [css] = useStyletron();
    return (
        <div
            className={css({
                height: '100vh',
            })}
        >
            <div
                className={css({
                    backgroundColor: '#f5f5f5',
                    minWidth: '100%',
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                })}
            >
                <div className={css({
                    '@media screen and (min-width: 880px)': {
                        position: 'relative',
                    },
                })}
                >
                    <Switch>
                        <Route exact path="/" component={HomeController} />
                        <Route exact path="/assessment" component={QuestionsController} />
                        <Route exact path="/pass" component={PassController} />
                        <Route exact path="/student-report" component={StudentReport} />
                        <Route exact path="/report" component={Report} />
                        <Route exact path="/checkin" component={CheckInController} />
                        <Route exact path="/checkin/:area/:location" component={CheckInController} />
                        <Route exact path="/adminpass" component={AdminPassController} />
                    </Switch>

                    <UpdateAppController />

                </div>
                <div className={css({
                    position: 'fixed', width: '100%', bottom: '0', padding: '10px', color: '#FFFFFF', backgroundColor: '#232f3e', height: '20px', textAlign: 'center', fontSize: '10px', '@media screen and (min-width: 564px)': { fontSize: '18px' },
                })}
                >
                    Copyright 2020 - Rochester Regional Health - Emerging Technologies
                </div>
            </div>
        </div>
    );
};

export default App;
