import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Input } from 'baseui/input';
import QrReader from 'react-qr-reader';
import Header from '../controllers/header';

const CheckIn = ({
    user, hasPass, onQRCheckin, onManualCheckIn, qrCheckIn, manualCheckIn, onQRError, onQRScan, qrError, checkpoint, onCancel, onSubmit, checkins, onLogin,
}) => {
    // STYLES

    const [css] = useStyletron();

    // STATE

    const [area, setArea] = useState('');
    const [location, setLocation] = useState('');
    let manualCheckpoint;
    if (manualCheckIn) {
        manualCheckpoint = { area, location };
    }

    if (!user) {
        return (
            <>
                <Helmet>
                    <title>SJFC Self Assessment</title>
                </Helmet>

                <Header />

                <div className={css({ margin: '10%', textAlign: 'center' })}>
                    <p>You are not currently logged in. You must log in as a valid and authorized user to proceed.</p>
                    <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
                </div>

                <div className={css({ height: '75px' })} />
            </>
        );
    }

    if (hasPass === false) {
        return (
            <>
                <Helmet>
                    <title>SJFC Self Assessment</title>
                </Helmet>

                <Header />

                <div className={css({
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginTop: '30px;',
                    marginLeft: '30px',
                    marginRight: '30px',
                    border: 'solid 1px #000000',
                    borderRadius: '8px',
                    padding: '15px',
                    fontSize: '12px',
                    '@media screen and (min-width: 300px)': { fontSize: '14px' },
                    '@media screen and (min-width: 564px)': { fontSize: '16px' },
                    '@media screen and (min-width: 764px)': { fontSize: '18px' },
                    '@media screen and (min-width: 960px)': { fontSize: '18px' },
                })}
                >
                    <div><i className={`${css({ color: '#f76902', fontSize: '100px' })} fas fa-exclamation-triangle`} /></div>
                    <div className={css({
                        fontWeight: 'bold', fontSize: '22px', padding: '15px', paddingTop: '30px',
                    })}
                    >You must complete a <Link to="/assessment">self assessment</Link> before checking into a building  and room on campus.
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>SJFC Self Assessment</title>
            </Helmet>

            <Header />

            <div className={css({
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '0',
                marginRight: '0',
                marginTop: '0',
                '@media screen and (min-width: 564px)': {
                    marginLeft: '20%',
                    marginRight: '20%',
                },
            })}
            >
                <h2 className={css({ textTransform: 'uppercase', paddingLeft: '15px' })}>Check In</h2>

                { (!checkpoint || checkpoint.native !== true) && (
                    <div className={css({
                        flex: '1', display: 'flex', flexWrap: 'wrap', marginBottom: '15px', minHeight: '50px',
                    })}
                    >

                        <button
                            className={css({
                                whiteSpace: 'nowrap',
                                marginLeft: '8px;',
                                marginRight: '8px;',
                                marginBottom: '8px;',
                                flex: '1',
                                textAlign: 'center',
                                height: '38px',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                backgroundColor: '#993333',
                                color: '#FFFFFF',
                                borderRadius: '20px',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                fontSize: '12px',
                                '@media screen and (min-width: 300px)': { fontSize: '14px' },
                                '@media screen and (min-width: 564px)': { fontSize: '16px' },
                                '@media screen and (min-width: 764px)': { fontSize: '18px' },
                                '@media screen and (min-width: 960px)': { fontSize: '18px' },
                            })}
                            onClick={() => onQRCheckin()}
                        >Check In With QR Code
                        </button>

                        <button
                            className={css({
                                whiteSpace: 'nowrap',
                                marginLeft: '8px;',
                                marginRight: '8px;',
                                marginBottom: '8px;',
                                flex: '1',
                                textAlign: 'center',
                                height: '38px',
                                textDecoration: 'none',
                                cursor: 'pointer',
                                backgroundColor: '#993333',
                                color: '#FFFFFF',
                                borderRadius: '20px',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                fontSize: '12px',
                                '@media screen and (min-width: 300px)': { fontSize: '14px' },
                                '@media screen and (min-width: 564px)': { fontSize: '16px' },
                                '@media screen and (min-width: 764px)': { fontSize: '18px' },
                                '@media screen and (min-width: 960px)': { fontSize: '18px' },
                            })}
                            onClick={() => onManualCheckIn()}
                        >Check In Manually
                        </button>
                    </div>)}

                { qrCheckIn && (
                    <div className={css({
                        paddingLeft: '5%',
                        paddingRight: '5%',
                        '@media screen and (min-width: 300px)': { paddingLeft: '5%', paddingRight: '5%' },
                        '@media screen and (min-width: 564px)': { paddingLeft: '10%', paddingRight: '10%' },
                        '@media screen and (min-width: 764px)': { paddingLeft: '25%', paddingRight: '25%' },
                        '@media screen and (min-width: 960px)': { paddingLeft: '30%', paddingRight: '30%' },
                    })}
                    >
                        <QrReader
                            delay={300}
                            onError={onQRError}
                            onScan={onQRScan}
                            style={{ width: '100%' }}
                        />
                    </div>
                )}

                { qrError && (
                    <div className={css({ paddingLeft: '30%', paddingRight: '30%', fontWeight: 'bold' })}>
                        { qrError }
                    </div>
                )}

                { ((checkpoint && checkpoint.qr !== true) || manualCheckIn) && (
                    <div className={css({ paddingLeft: '10%', paddingRight: '10%', display: 'flex' })}>
                        <div className={css({ marginRight: '20px' })}>
                            <div className={css({ fontWeight: 'bold' })}>Area/Building</div>
                            <Input
                                value={area}
                                onChange={e => setArea(e.target.value)}
                                placeholder=""
                            />
                        </div>
                        <div>
                            <div className={css({ fontWeight: 'bold' })}>Location</div>
                            <Input
                                value={location}
                                onChange={e => setLocation(e.target.value)}
                                placeholder=""
                            />
                        </div>
                    </div>
                )}

                { ((checkpoint && checkpoint.qr === true)) && (
                    <div className={css({
                        paddingLeft: '10%', paddingRight: '10%', display: 'flex', justifyContent: 'center',
                    })}
                    >
                        <div className={css({
                            marginRight: '20px', flex: '1', maxWidth: '150px', textAlign: 'center',
                        })}
                        >
                            <div className={css({ fontWeight: 'bold' })}>Area/Building</div>
                            {checkpoint.area}
                        </div>
                        <div className={css({ flex: '1', maxWidth: '150px', textAlign: 'center' })}>
                            <div className={css({ fontWeight: 'bold' })}>Location</div>
                            {checkpoint.location}
                        </div>
                    </div>
                )}


                { (checkpoint || (manualCheckpoint && manualCheckpoint.area.trim() !== '' && manualCheckpoint.location.trim() !== '')) && (
                    <div className={css({ marginTop: '20px', textAlign: 'center' })}>

                        <button
                            className={css({
                                margin: '30px;', flex: '1', textAlign: 'center', height: '38px', textDecoration: 'none', cursor: 'pointer', backgroundColor: '#993333', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                            })}
                            onClick={() => onSubmit(manualCheckpoint || checkpoint)}
                        >Check In
                        </button>


                        <button
                            className={css({
                                margin: '30px;', flex: '1', textAlign: 'center', height: '38px', textDecoration: 'none', cursor: 'pointer', backgroundColor: '#666666', color: '#FFFFFF', borderRadius: '20px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px', fontSize: '18px',
                            })}
                            onClick={() => onCancel()}
                        >Cancel
                        </button>
                    </div>)}

                { (checkins.length > 0) && (
                    <div className={css({
                        marginTop: '30px',
                        paddingLeft: '5%',
                        paddingRight: '5%',
                        '@media screen and (min-width: 300px)': { paddingLeft: '5%', paddingRight: '5%' },
                        '@media screen and (min-width: 564px)': { paddingLeft: '10%', paddingRight: '10%' },
                        '@media screen and (min-width: 764px)': { paddingLeft: '20%', paddingRight: '20%' },
                        '@media screen and (min-width: 960px)': { paddingLeft: '20%', paddingRight: '20%' },
                    })}
                    >
                        <div className={css({ fontWeight: 'bold', marginBottom: '15px' })}>Locations You Checked Into Recently</div>

                        <table className={css({ width: '100%' })}>
                            <tbody>
                                <tr className={css({ borderCollapse: 'collapse' })}>
                                    <td className={css({ borderBottom: 'solid 1px #cbcbcb', fontWeight: 'bold' })}>Area - Location</td>
                                    <td className={css({ borderBottom: 'solid 1px #cbcbcb', fontWeight: 'bold' })}>Checked In</td>
                                </tr>
                                { _.sortBy(checkins, ['created']).reverse().map(ci => (
                                    <tr key={ci.id}>
                                        <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>{ci.area} - {ci.location}</td>
                                        <td className={css({ borderBottom: 'solid 1px #cbcbcb' })}>{moment.unix(ci.created).format('MM/DD hh:mm A')}</td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </div>
                )}


            </div>
            <div className={css({ height: '75px' })} />
        </>
    );
};

export default CheckIn;
