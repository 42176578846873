import { SET_CURRENT_CHECKPOINT, SET_CHECKPOINTS, SET_CHECKINS } from './checkpoint-actions';

export function checkpoint(state = null, action) {
    let newState;
    switch (action.type) {
    case (SET_CURRENT_CHECKPOINT):
        newState = action.checkpoint ? Object.assign({}, action.checkpoint) : null;
        return newState;
    default:
        return state;
    }
}

export function checkpoints(state = [], action) {
    let newState;
    switch (action.type) {
    case (SET_CHECKPOINTS):
        newState = Array.from(action.checkpoints);
        return newState;
    default:
        return state;
    }
}

export function checkins(state = [], action) {
    let newState;
    switch (action.type) {
    case (SET_CHECKINS):
        newState = Array.from(action.checkins);
        return newState;
    default:
        return state;
    }
}
