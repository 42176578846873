import { connect } from 'react-redux';
import moment from 'moment';
import Home from '../components/home';
import { setUIState } from '../state/ui-actions';
import { fetchReport } from '../state/assessment-actions';
import { uploadStudentCSV } from '../state/user-actions';

const mapStateToProps = (state) => {
    let hasPass = true; // TOGGLE TO FALSE TO LIMIT CHECKINS OT HAVING PASS
    let passDate;
    if (state.assessment) {
        hasPass = true;
        passDate = moment.unix(typeof (state.assessment.created.seconds) !== 'undefined' ? state.assessment.created.seconds : state.assessment.created);
        const now = moment();
        const diff = now.diff(passDate, 'hours');
        if (diff > 24) {
            hasPass = true; // TOGGLE TO FALSE TO LIMIT CHECKINS OT HAVING PASS
        }
    }

    return {
        user: state.user ? state.user : null,
        loggingIn: typeof (state.ui.loggingIn) !== 'undefined' ? state.ui.loggingIn : false,
        reportDates: typeof (state.ui.reportDates) !== 'undefined' ? state.ui.reportDates : [],
        fetchingReport: typeof (state.ui.fetchingReport) !== 'undefined' ? state.ui.fetchingReport : false,
        csvDownloadReady: typeof (state.ui.csvDownloadReady) !== 'undefined' ? state.ui.csvDownloadReady : false,
        assessments: state.assessments,
        appMessage: typeof (state.ui.appMessage) !== 'undefined' ? state.ui.appMessage : false,
        loginError: typeof (state.ui.loginError) !== 'undefined' ? state.ui.loginError : false,
        studentCSVFile: typeof (state.ui.studentCSVFile) !== 'undefined',
        showCheckIn: typeof (state.ui.showCheckIn) !== 'undefined' ? state.ui.showCheckIn : false,
        hasPass,
    };
};

const mapDispatchToProps = dispatch => ({
    onLogin: () => {
        window.location.href = '/login.html';
    },
    onLogout: () => {},
    onEmailChanged: (email) => {
        dispatch(setUIState('emailToApprove', email));
    },
    onSetDates: (dates) => {
        dispatch(setUIState('reportDates', dates));
    },
    onFetchReport: () => {
        dispatch(fetchReport());
    },
    onDownloadReport: () => {
        dispatch(setUIState('csvDownloadReady', false));
    },
    onSetStudentFile: (file) => {
        dispatch(setUIState('studentCSVFile', file));
    },
    onUploadStudentCSV: () => {
        dispatch(uploadStudentCSV());
    },
});

const HomeController = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeController;
