import { connect } from 'react-redux';
import moment from 'moment';
import Header from '../components/header';
import { showLogin } from '../state/user-actions';

const mapStateToProps = (state) => {
    let hasPass = true; // TOGGLE TO FALSE TO LIMIT CHECKINS OT HAVING PASS
    let passDate;
    if (state.assessment) {
        hasPass = true;
        passDate = moment.unix(typeof (state.assessment.created.seconds) !== 'undefined' ? state.assessment.created.seconds : state.assessment.created);
        const now = moment();
        const diff = now.diff(passDate, 'hours');
        if (diff > 24) {
            hasPass = true; // TOGGLE TO FALSE TO LIMIT CHECKINS OT HAVING PASS
        }
    }

    return {
        user: state.user ? state.user : null,
        path: state.router.location.pathname,
        appMessage: typeof (state.ui.appMessage) !== 'undefined' ? state.ui.appMessage : false,
        showCheckIn: typeof (state.ui.showCheckIn) !== 'undefined' ? state.ui.showCheckIn : false,
        hasPass,
    };
};

const mapDispatchToProps = dispatch => ({
    onShowLogin: () => {
        dispatch(showLogin());
    },
    onLogout: () => {
        window.localStorage.setItem('RRHSAJWToken', null);
        setTimeout(() => {
            window.location.href = '/';
        }, 1000);
    },
});

const HeaderController = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderController;
