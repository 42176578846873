import { takeLatest, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SAGA_INIT_APP, SAGA_SAVE_USER, SAGA_UPLOAD_STUDENT_CSV, setUserInfo, saveUser as actionSaveUser, unregisterLoginAction } from './user-actions';
import { fetchLatestAssessment, fetchLatestDenied } from './assessment-actions';
import { setUIState } from './ui-actions';
import { fetchCheckpoints, setCurrentCheckpoint } from '../state/checkpoint-actions';

function* workerInitApp() {
    // TEMP MESSAGE

    // yield put(setUIState('appMessage', true));
    // return;

    // TOKEN FROM COGNITO

    if (window.location.href.indexOf('login=true') >= 0) {
        yield put(setUIState('loggingIn', true));
    }

    if (window.location.href.indexOf('error') >= 0) {
        const error = window.location.href.split('?')[1].split('=')[1];
        const errorText = unescape(error).split('+').join(' ');
        console.error(errorText);
        alert(errorText);   // eslint-disable-line
        return;
    }

    // Get user token from local storage.

    let token;
    token = window.localStorage.getItem('RRHSAJWToken'); // eslint-disable-line no-undef

    // Get User Info

    let user;
    if (token && typeof (token.length) !== 'undefined') {
        const response = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=getUserInfo&token=${token}&pool=us-east-1_0TTjV5HrZ`);
        const result = yield response.json();
        user = result.body;

        // INVALIDATE USER IF ERROR

        if (user.error && user.error.name === 'TokenExpiredError') {
            console.warn('User token expired');
            yield put(setUserInfo(null));
            window.location.href = '/login.html';
            return;
        }

        if (user.error) {
            console.error('User token error:', user.error);
            yield put(setUserInfo(null));
            alert('There was a problem with your login credentials. Please retry or contact support.'); // eslint-disable-line
            return;
        }

        // GET ROLES

        user.roles = [];
        if (typeof (user.decoded.profile) !== 'undefined') {
            user.roles = user.decoded.profile.replace(/[\[\]\s]/g, '').split(',').map(s => s.toLowerCase()); // eslint-disable-line
        }
        if (user.roles.indexOf('student') >= 0) {
            user.role = 'STUDENT';
        } else {
            user.role = 'EMPLOYEE';
        }

        // GET ADMIN

        if (user.userData && typeof (user.userData.role) !== 'undefined' && user.userData.role.toLowerCase() === 'passmanager') {
            user.adminPass = true;
        } else {
            user.adminPass = false;
        }
    } else {
        console.warn('No JWT Token');
        token = null;
        yield put(setUserInfo(null));
        window.location.href = '/login.html';
        return;
    }

    // STORE USER

    user.token = token;
    yield put(actionSaveUser(Object.assign(user)));

    // IF WE HAVE QR AREA AND LOCATION, GO TO CHECKIN

    const area = window.localStorage.getItem('QRCHECKINAREA');
    const location = window.localStorage.getItem('QRCHECKINLOCATION');
    if (area && area.trim() !== '' && location && location.trim() !== '') {
        console.log('SETTING QR CHECKPOINT AFTER LOGIN');
        yield put(setCurrentCheckpoint({
            area, location, qr: true, native: true,
        }));
        window.localStorage.removeItem('QRCHECKINAREA');
        window.localStorage.removeItem('QRCHECKINLOCATION');
        yield put(push('/checkin'));
    }

    // HANDLE REGISTERED LOGIN ACTIONS

    const actions = yield select(state => state.loginActions);
    for (let idx = 0; idx < actions.length; idx++) {
        yield put(actions[idx]);
        yield put(unregisterLoginAction(actions[idx]));
    }
}

export function* initApp() {
    yield takeLatest(SAGA_INIT_APP, workerInitApp);
}

function* workerSaveUser(action) {
    const user = {
        email: action.userInfo.email,
        handle: action.userInfo.firstName,
        firstName: action.userInfo.firstName,
        lastName: action.userInfo.lastName,
        token: action.userInfo.token,
        userName: action.userInfo.userName,
        userData: action.userInfo.userData,
        role: action.userInfo.role,
        roles: action.userInfo.roles,
        adminPass: action.userInfo.adminPass,
    };

    // SAVE USER

    yield put(setUserInfo(user));

    // FETCH LATEST ASSESSMENT

    yield put(fetchLatestAssessment());

    // FETCH CHECKPOINTS

    const showCheckIn = yield select(state => state.ui.showCheckIn);
    if (showCheckIn) {
        const installation = yield select(state => state.ui.installation);
        yield put(fetchCheckpoints(installation));
    }

    // FETCH DENIED IF ROLE REQUIRES

    if (user.adminPass) {
        yield put(fetchLatestDenied());
    }
}

export function* saveUser() {
    yield takeLatest(SAGA_SAVE_USER, workerSaveUser);
}

// WATCH FOR PATH CHANGES AND DISPATCH APPROPRIATE INIT SAGAS

function* workerWatchLocation(action) { // eslint-disable-line

    const path = action.payload.location.pathname;
    const host = window.location.hostname;  // eslint-disable-line
    const parts = path.split('/');
    const section = parts[1];
    console.log('PATH:', path, parts);

    // TEMP MESSAGE

    // yield put(setUIState('appMessage', true));
    // if (section !== '') {
    //     yield put(push('/'));
    // }
    // return;

    // CLEAR DOWNLOAD FLAGS AND ASSESSMENT

    yield put(setUIState('csvDownloadReady', false));
    yield put(setUIState('assessment', null));
    yield put(setUIState('qrError', null));
    yield put(setUIState('qrCheckIn', false));
    yield put(setUIState('manualCheckIn', false));

    // SWITCH ON SECTION

    console.log('SECTION:', section);
    switch (section) {
    case (''): {
        break;
    }
    case ('checkin'): {
        if (parts.length === 4) {
            if (parts[2].trim() !== '' && parts[3].trim() !== '') {
                const user = yield select(state => state.user);
                if (user) {
                    yield put(setCurrentCheckpoint({
                        area: parts[2], location: parts[3], qr: true, native: true,
                    }));
                } else {
                    window.localStorage.setItem('QRCHECKINAREA', parts[2]);
                    window.localStorage.setItem('QRCHECKINLOCATION', parts[3]);
                }
            } else {
                yield put(setUIState('qrError', 'There was an error in the QR Code format. Please retry or scan a different QR Code.'));
            }
        }
        break;
    }
    case ('adminpass'): {
        break;
    }
    default:
    }
}

export function* watchLocation() {
    yield takeLatest('@@router/LOCATION_CHANGE', workerWatchLocation);
}

const toText = file => new Promise((resolve, reject) => {
    const reader = new FileReader();        // eslint-disable-line
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

function* workerUploadStudentCSV() {
    // GET USER

    const user = yield select(state => state.user);

    // GET CSV FILE OBJECT

    const csvFile = yield select(state => state.ui.studentCSVFile);

    // CONVERT TO BASE 64

    const csvData = yield toText(csvFile);
    const form = {
        method: 'uploadStudentCSV',
        token: user.token,
        csv: csvData,
    };
    const json = JSON.stringify(form);

    // SEND USING FETCH

    const response = yield fetch(
        `https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=uploadStudentCSV&token=${user.token}&pool=us-east-1_0TTjV5HrZ`,
        {
            method: 'POST',
            body: json,
        },
    );
    const result = yield response.json();
    console.log('RESPONSE:', result);
}

export function* uploadStudentCSV() {
    yield takeLatest(SAGA_UPLOAD_STUDENT_CSV, workerUploadStudentCSV);
}
