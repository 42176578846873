import { takeLatest, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { uuid } from 'uuidv4';
import moment from 'moment';
import { SAGA_FETCH_ASSESSMENT_BY_USERNAME, SAGA_LOG_RED_PASS, SAGA_FETCH_LATEST_DENIED, SAGA_LOG_ASSESSMENT, SAGA_FETCH_LATEST_ASSESSMENT, SAGA_FETCH_REPORT, SAGA_FETCH_STUDENT_REPORT, SAGA_FETCH_GENERAL_REPORT, SAGA_OVERRIDE_ASSESSMENT, setAssessment, setAssessments, setNoCheckins, setAssessmentCounts, fetchLatestDenied as actionFetchLatestDenied } from './assessment-actions';
import { setUIState } from './ui-actions';

function* workerLogRedPass(action) {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // let userName = action.userName.trim();
    // if (userName.indexOf('sjfc_') < 0) {
    //     userName = `sjfc_${userName}`;
    // }

    const { assessment, note } = action;

    // GET ASSESSMENT

    assessment.id = uuid();
    assessment.status = 'DENIED';
    if (note.indexOf('Direct green pass issued') >= 0) {
        assessment.status = 'APPROVED';
    }
    assessment.installation = 'SJFC';
    assessment.note = note;
    assessment.overridden = null;
    assessment.firstName = assessment.firstName.replace(/'/g, '');
    assessment.lastName = assessment.lastName.replace(/'/g, '');
    delete assessment.created;

    // STORE LOCAL

    const local = Object.assign({}, assessment);
    local.created = { seconds: moment().unix() };

    // STORE

    yield put(setUIState('logError', false));
    yield put(setUIState('logSaving', true));
    try {
        const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=logAssessment&token=${user.token}&pool=us-east-1_0TTjV5HrZ&assessment=${encodeURI(JSON.stringify(assessment))}`);
        const result = yield res.json();
        const { body } = result;
        if (body.error || typeof (body.error) !== 'undefined') {
            yield put(setUIState('logSaving', false));
            yield put(setUIState('logError', true));
            return;
        }
    } catch (e) {
        yield put(setUIState('logSaving', false));
        yield put(setUIState('logError', true));
        return;
    }

    // CLEAR ERRORS

    yield put(setUIState('logError', false));
    yield put(setUIState('logSaving', false));

    // REFETCH

    yield put(actionFetchLatestDenied());
}

export function* logRedPass() {
    yield takeLatest(SAGA_LOG_RED_PASS, workerLogRedPass);
}

function* workerLogAssessment(action) {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET ASSESSMENT

    const { assessment } = action;
    assessment.id = uuid();
    assessment.user = user.userName;
    assessment.role = user.role;
    assessment.email = user.email.toLowerCase();
    let status = 'APPROVED';

    if (assessment.q13 !== 'no') {
        status = 'DENIED';
    }
    if (assessment.q14 !== 'no' && assessment.q16 !== 'yes') {
        status = 'DENIED';
    }
    if (assessment.q15 !== 'no') {
        status = 'DENIED';
    }

    if (assessment.q0 === 'no') {
        status = 'NOT_ATTENDING';
    }
    assessment.status = status;
    assessment.installation = 'SJFC';

    // STORE LOCAL

    const local = Object.assign({}, assessment);
    local.created = { seconds: moment().unix() };

    // STORE

    yield put(setUIState('logError', false));
    yield put(setUIState('logSaving', true));
    try {
        const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=logAssessment&token=${user.token}&pool=us-east-1_0TTjV5HrZ&assessment=${encodeURI(JSON.stringify(assessment))}`);
        const result = yield res.json();
        const { body } = result;
        if (body.error || typeof (body.error) !== 'undefined') {
            yield put(setUIState('logSaving', false));
            yield put(setUIState('logError', true));
            return;
        }
    } catch (e) {
        yield put(setUIState('logSaving', false));
        yield put(setUIState('logError', true));
        return;
    }

    // VALIDATE

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchLatestAssessment&token=${user.token}&pool=us-east-1_0TTjV5HrZ`);
    const result = yield res.json();
    const { body } = result;
    const latestAssessment = body.data.length ? body.data[0] : null;

    console.log('CURRENT:', assessment);
    console.log('LATEST:', latestAssessment);
    if (assessment.id !== latestAssessment.id) {
        yield put(setUIState('logSaving', false));
        yield put(setUIState('logError', true));
        return;
    }

    // CLEAR AND STORE LOCAL

    yield put(setAssessment(latestAssessment));
    yield put(setUIState('logError', false));
    yield put(setUIState('logSaving', false));

    if (action.path) {
        yield put(push(action.path));
    }
}

export function* logAssessment() {
    yield takeLatest(SAGA_LOG_ASSESSMENT, workerLogAssessment);
}

function* workerFetchLatestAssessment() {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchLatestAssessment&token=${user.token}&pool=us-east-1_0TTjV5HrZ`);
    const result = yield res.json();
    const { body } = result;
    const assessment = body.data.length ? body.data[0] : null;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessment(assessment));
}

export function* fetchLatestAssessment() {
    yield takeLatest(SAGA_FETCH_LATEST_ASSESSMENT, workerFetchLatestAssessment);
}

function* workerFetchReport() {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET DATES

    const dates = yield select(state => state.ui.reportDates);
    const start = moment(dates[0]).startOf('day').unix();
    const end = moment(dates[1]).endOf('day').unix();

    // FETCH ALL ASSESSMENTS

    yield put(setAssessments([]));
    yield put(setUIState('fetchingReport', true));
    yield put(setUIState('csvDownloadReady', false));

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchReport&clean=yes&token=${user.token}&pool=us-east-1_0TTjV5HrZ&start=${start}&end=${end}&installation=SJFC`);
    const result = yield res.json();
    const { body } = result;
    const assessments = body.data;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessments(assessments));
    yield put(setUIState('csvDownloadReady', true));
    yield put(setUIState('fetchingReport', false));
}

export function* fetchReport() {
    yield takeLatest(SAGA_FETCH_REPORT, workerFetchReport);
}


function* workerFetchStudentReport() {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET DATES

    const dates = yield select(state => state.ui.reportDates);
    const start = moment(dates[0]).startOf('day').unix();
    const end = moment(dates[1]).endOf('day').unix();

    // FETCH ALL ASSESSMENTS

    yield put(setAssessments([]));
    yield put(setNoCheckins([]));
    yield put(setAssessmentCounts({ approved: 0, denied: 0, noCheckin: 0 }));
    yield put(setUIState('fetchingReport', true));

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchReport&position=STUDENT&token=${user.token}&pool=us-east-1_0TTjV5HrZ&start=${start}&end=${end}&installation=SJFC`);
    const result = yield res.json();
    const { body } = result;
    const assessments = body.data;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessmentCounts({
        approved: body.approved, denied: body.denied, noCheckin: body.noCheckin, byDay: body.byDay, byMonth: body.byMonth,
    }));
    yield put(setAssessments(assessments));
    yield put(setNoCheckins(body.noCheckInUsers));
    yield put(setUIState('fetchingReport', false));
}

export function* fetchStudentReport() {
    yield takeLatest(SAGA_FETCH_STUDENT_REPORT, workerFetchStudentReport);
}

function* workerFetchGeneralReport() {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET DATES

    const dates = yield select(state => state.ui.reportDates);
    const start = moment(dates[0]).startOf('day').unix();
    const end = moment(dates[1]).endOf('day').unix();

    // FETCH ALL ASSESSMENTS

    yield put(setAssessments([]));
    yield put(setNoCheckins([]));
    yield put(setAssessmentCounts({ approved: 0, denied: 0, noCheckin: 0 }));
    yield put(setUIState('fetchingReport', true));

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchReport&supervisor=${encodeURI(user.userName)}&token=${user.token}&pool=us-east-1_0TTjV5HrZ&start=${start}&end=${end}&installation=SJFC`);
    const result = yield res.json();
    const { body } = result;
    const assessments = body.data;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessmentCounts({
        approved: body.approved, denied: body.denied, noCheckin: body.noCheckin, byDay: body.byDay, byMonth: body.byMonth,
    }));
    yield put(setAssessments(assessments));
    yield put(setNoCheckins(body.noCheckInUsers));
    yield put(setUIState('fetchingReport', false));
}

export function* fetchGeneralReport() {
    yield takeLatest(SAGA_FETCH_GENERAL_REPORT, workerFetchGeneralReport);
}

function* workerOverrideAssessment(action) {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET ASSESSMENT

    const { assessment } = action;
    assessment.overridden = true;
    assessment.overriddenBy = user.userName;
    assessment.firstName = assessment.firstName.replace(/'/g, '');
    assessment.lastName = assessment.lastName.replace(/'/g, '');
    assessment.note = action.note;

    // MARK OLD AS UPDARED

    yield put(setUIState('logError', false));
    yield put(setUIState('logSaving', true));
    try {
        yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=updateAssessment&token=${user.token}&pool=us-east-1_0TTjV5HrZ&assessment=${encodeURI(JSON.stringify(assessment))}`);
    } catch (e) {
        yield put(setUIState('logSaving', false));
        yield put(setUIState('logError', true));
        return;
    }
    yield put(setUIState('logError', false));
    yield put(setUIState('logSaving', false));

    // CREATE NEW

    assessment.status = 'APPROVED';
    assessment.id = uuid();
    delete assessment.created;
    yield put(setUIState('logError', false));
    yield put(setUIState('logSaving', true));
    try {
        yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=logAssessment&token=${user.token}&pool=us-east-1_0TTjV5HrZ&assessment=${encodeURI(JSON.stringify(assessment))}`);
    } catch (e) {
        yield put(setUIState('logSaving', false));
        yield put(setUIState('logError', true));
        return;
    }
    yield put(setUIState('logError', false));
    yield put(setUIState('logSaving', false));

    // REFETCH

    yield put(actionFetchLatestDenied());
}

export function* overrideAssessment() {
    yield takeLatest(SAGA_OVERRIDE_ASSESSMENT, workerOverrideAssessment);
}

function* workerFetchLatestDenied() {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // GET DATES

    const start = moment().add(-6, 'weeks').startOf('day').unix();
    const end = moment().endOf('day').unix();

    // FETCH ALL ASSESSMENTS

    yield put(setAssessments([]));
    yield put(setUIState('fetchingPasses', true));

    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchAssessmentsByStatus&token=${user.token}&pool=us-east-1_0TTjV5HrZ&start=${start}&end=${end}&status=DENIED&installation=SJFC`);
    const result = yield res.json();
    const { body } = result;
    const assessments = body.data;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessmentCounts({
        approved: body.approved, denied: body.denied, noCheckin: body.noCheckin, byDay: body.byDay, byMonth: body.byMonth,
    }));
    yield put(setAssessments(assessments));
    yield put(setUIState('fetchingPasses', false));
}

export function* fetchLatestDenied() {
    yield takeLatest(SAGA_FETCH_LATEST_DENIED, workerFetchLatestDenied);
}

function* workerFetchAssessmentsByUsername(action) {
    // GET USER

    const user = yield select(state => state.user);
    if (!user) {
        return;
    }

    // FETCH ALL ASSESSMENTS

    yield put(setAssessments([]));
    yield put(setUIState('fetchingPasses', true));

    const userName = !action.userName.startsWith('sjfc_') ? `sjfc_@${action.userName}` : action.userName;
    const res = yield fetch(`https://fnydcwnof2.execute-api.us-east-1.amazonaws.com/default/dispatch?method=fetchAssessmentsByUsername&token=${user.token}&pool=us-east-1_0TTjV5HrZ&&userName=${userName}`);
    const result = yield res.json();
    const { body } = result;
    const assessments = body.data;

    // INVALIDATE USER IF ERROR

    if (body.error && body.error.name === 'TokenExpiredError') {
        console.warn('User token expired');
        window.location.href = '/login.html';
        return;
    }

    yield put(setAssessmentCounts({
        approved: body.approved, denied: body.denied, noCheckin: body.noCheckin, byDay: body.byDay, byMonth: body.byMonth,
    }));
    yield put(setAssessments(assessments));
    yield put(setUIState('fetchingPasses', false));
}

export function* fetchAssessmentsByUsername() {
    yield takeLatest(SAGA_FETCH_ASSESSMENT_BY_USERNAME, workerFetchAssessmentsByUsername);
}
