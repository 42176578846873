import { connect } from 'react-redux';
import moment from 'moment';
import Pass from '../components/pass';
import { setUIState } from '../state/ui-actions';

const mapStateToProps = (state) => {
    let expired = false;
    let passDate;
    if (state.assessment) {
        passDate = moment.unix(typeof (state.assessment.created.seconds) !== 'undefined' ? state.assessment.created.seconds : state.assessment.created);
        const thisMidnight = moment(`${moment().format('YYYY-MM-DD')} 00:01:00`).unix();
        if (passDate.isBefore > thisMidnight) {
            expired = true;
        }
    }

    return {
        user: state.user,
        assessment: state.assessment,
        passDate,
        expired,
        downloadPDF: typeof (state.ui.downloadPDF) !== 'undefined' ? state.ui.downloadPDF : false,
        logError: typeof (state.ui.logError) !== 'undefined' ? state.ui.logError : null,
        logSaving: typeof (state.ui.logSaving) !== 'undefined' ? state.ui.logSaving : false,
    };
};

const mapDispatchToProps = dispatch => ({
    onDownloadPDF: () => {
        dispatch(setUIState('downloadPDF', true));
    },
});

const PassController = connect(mapStateToProps, mapDispatchToProps)(Pass);

export default PassController;
