import { all } from 'redux-saga/effects';
import { initApp, saveUser, watchLocation, uploadStudentCSV } from './user-sagas';
import { logRedPass, logAssessment, fetchLatestAssessment, fetchLatestDenied, overrideAssessment, fetchReport, fetchStudentReport, fetchGeneralReport, fetchAssessmentsByUsername } from './assessment-sagas';
import { fetchCheckpoint, fetchCheckpoints, checkin } from './checkpoint-sagas';

// single entry point to start all Sagas at once

export default function* rootSaga() {
    yield all([
        // USER SAGAS

        initApp(),
        saveUser(),
        watchLocation(),
        uploadStudentCSV(),

        // ASSESSMENTS

        logAssessment(),
        logRedPass(),
        fetchLatestAssessment(),
        fetchReport(),
        fetchStudentReport(),
        fetchGeneralReport(),
        overrideAssessment(),
        fetchLatestDenied(),
        fetchAssessmentsByUsername(),

        // CHECKPOINTS

        fetchCheckpoint(),
        fetchCheckpoints(),
        checkin(),

    ]);
}
