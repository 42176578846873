import React from 'react';
import { useStyletron } from 'baseui';
import { Link } from 'react-router-dom';
import { Avatar } from 'baseui/avatar';
import moment from 'moment';
import logo from '../images/logo.png';

const Header = ({
    user, onShowLogin, onLogout, path, appMessage, showCheckIn, hasPass,
}) => {
    const [css] = useStyletron();
    const cardRootStyle = {};
    cardRootStyle.color = '#FFFFFF';
    cardRootStyle.backgroundColor = '#993333';
    cardRootStyle.borderBottomColor = '#000000';
    cardRootStyle.borderBottomWidth = '1px';
    cardRootStyle.borderBottomStyle = 'solid';
    cardRootStyle.paddingTop = '10px;';
    cardRootStyle.paddingLeft = '10px;';
    cardRootStyle.paddingBottom = '5px;';
    cardRootStyle.paddingRight = '10px;';

    return (
        <div className={css(cardRootStyle)}>

            <div className={css({
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 0,
                zIndex: '1000',
            })}
            >
                <Link to="/" className={css({ flex: '1', maxWidth: '300px' })}><img className={css({ maxWidth: '100px', minWidth: '100px' })} src={logo} alt="St. John Fisher College" /></Link>
                <div className={css({
                    flex: '2',
                    textAlign: 'left',
                    fontSize: '14px',
                    paddingTop: '5px',
                    display: 'none',
                    '@media screen and (min-width: 564px)': { fontSize: '16px', paddingTop: '5px;', display: 'block' },
                    '@media screen and (min-width: 764px)': { fontSize: '26px' },
                    '@media screen and (min-width: 960px)': { fontSize: '36px' },
                })}
                >Daily Pass
                </div>
                { user && (
                    <div className={css({
                        flex: 1, maxWidth: '200px', display: 'flex', justifyContent: 'flex-end',
                    })}
                    >
                        <div className={css({
                            position: 'relative',
                            top: '1px',
                            marginRight: '10px',
                            display: 'flex',
                            flexDirection: 'row',
                        })}
                        >
                            <div className={css({ fontSize: '14px', whiteSpace: 'wrap', textAlign: 'center' })}>{user.firstName} {user.lastName}</div>
                            <div className={css({ textAlign: 'center', marginLeft: '10px' })}>
                                <button
                                    className={css({
                                        cursor: 'pointer', backgroundColor: '#000000', color: '#ffffff', border: 'none', borderRadius: '5px', fontSize: '12px',
                                    })}
                                    onClick={onLogout}
                                >Not You?
                                </button>
                            </div>
                        </div>

                        { user.photoURL && <Avatar
                            name={user ? user.handle : ''}
                            size="scale1200"
                            src={user.photoURL}
                        /> }
                    </div>)}

                { (!user && appMessage === false) && (
                    <div className={css({ flex: 1, maxWidth: '200px' })}>
                        <a
                            href="/login.html"
                            className={css({
                                cursor: 'pointer', backgroundColor: '#000000', color: '#FFFFFF', border: 'none', borderRadius: '5px', fontSize: '12px', padding: '5px',
                            })}
                            onClick={onShowLogin}
                        >Login
                        </a>
                    </div>
                )}
            </div>

            { (user && appMessage === false) && (
                <div className={css({
                    marginTop: '5px', display: 'flex', backgroundColor: '#232f3e', marginBottom: '-5px', marginLeft: '-10px', marginRight: '-10px', justifyContent: 'space-between', padding: '3px',
                })}
                >
                    <div
                        className={css({
                            flex: '1    ',
                            textAlign: 'left',
                            color: '#FFFFFF',
                            textDecoration: 'none',
                            fontSize: '12px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            '@media screen and (min-width: 564px)': { fontSize: '22px', paddingLeft: '20px' },
                        })}
                    >{moment().format('MM/DD/YYYY')}
                    </div>

                    <Link
                        to="/assessment"
                        className={css({
                            flex: '1',
                            textAlign: 'center',
                            color: path !== '/assessment' ? '#FFFFFF' : '#FFCC33',
                            textDecoration: 'none',
                            fontSize: '10px',
                            borderLeft: 'solid 1px #FFFFFF',
                            borderRight: 'solid 1px #FFFFFF',
                            ':hover': { color: '#FFCC33' },
                            '@media screen and (min-width: 300px)': { fontSize: '12px' },
                            '@media screen and (min-width: 564px)': { fontSize: '18px' },
                            '@media screen and (min-width: 764px)': { fontSize: '20px' },
                            '@media screen and (min-width: 960px)': { fontSize: '22px' },
                        })}
                    >Self Assessment
                    </Link>
                    
                    { (showCheckIn && hasPass) && (
                        <Link
                            to="/checkin"
                            className={css({
                                flex: '1',
                                textAlign: 'center',
                                color: path !== '/checkin' ? '#FFFFFF' : '#FFCC33',
                                textDecoration: 'none',
                                fontSize: '10px',
                                borderLeft: 'solid 1px #FFFFFF',
                                borderRight: 'solid 1px #FFFFFF',
                                ':hover': { color: '#FFCC33' },
                                '@media screen and (min-width: 300px)': { fontSize: '12px' },
                                '@media screen and (min-width: 564px)': { fontSize: '18px' },
                                '@media screen and (min-width: 764px)': { fontSize: '20px' },
                                '@media screen and (min-width: 960px)': { fontSize: '22px' },
                            })}
                        >Location Check In
                        </Link>
                    )}

                    <Link
                        to="/pass"
                        className={css({
                            flex: '1',
                            textAlign: 'center',
                            color: path !== '/pass' ? '#FFFFFF' : '#FFCC33',
                            textDecoration: 'none',
                            fontSize: '10px',
                            ':hover': { color: '#FFCC33' },
                            '@media screen and (min-width: 300px)': { fontSize: '12px' },
                            '@media screen and (min-width: 564px)': { fontSize: '18px' },
                            '@media screen and (min-width: 764px)': { fontSize: '20px' },
                            '@media screen and (min-width: 960px)': { fontSize: '22px' },
                        })}
                    >My Daily Pass
                    </Link>
                </div>
            )}
        </div>
    );
};


export default Header;
