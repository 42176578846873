import { connect } from 'react-redux';
import Questions from '../components/questions';
import { setUIState } from '../state/ui-actions';
import { logAssessment } from '../state/assessment-actions';

const mapStateToProps = (state) => {
    let denied = false;

    const user = state.user ? state.user : {};
    if (state.assessment && user.role === 'STUDENT' && state.assessment.status === 'DENIED') {
        denied = true;
    }

    return {
        user: state.user,
        denied,
        assessment: typeof (state.ui.assessment) !== 'undefined' && state.ui.assessment !== null ? state.ui.assessment : {},
        logError: typeof (state.ui.logError) !== 'undefined' ? state.ui.logError : null,
        logSaving: typeof (state.ui.logSaving) !== 'undefined' ? state.ui.logSaving : false,
    };
};

const mapDispatchToProps = dispatch => ({
    setAssessmentValue: (_assessment, field, value) => {
        const assessment = Object.assign({}, _assessment);
        assessment[field] = value;
        dispatch(setUIState('assessment', assessment));
        if (field === 'q0' && value === 'no') {
            dispatch(setUIState('assessment', null));
            dispatch(logAssessment(assessment, '/pass'));
        }
    },
    onSubmit: (assessment) => {
        dispatch(logAssessment(assessment, '/pass'));
    },
    onLogin: () => {
        window.location.href = '/login.html';
    },
});

const QuestionsController = connect(mapStateToProps, mapDispatchToProps)(Questions);

export default QuestionsController;
