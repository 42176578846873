import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { user, initActions, loginActions } from './user-reducers';
import { ui } from './ui-reducers';
import { checkpoint, checkpoints, checkins } from './checkpoint-reducers';
import { assessment, assessments, assessmentsCounts, noCheckins } from './assessment-reducers';

// BROWSER HISTORY

const createRootReducer = history => combineReducers({
    // USER

    user,
    initActions,
    loginActions,

    // UI

    ui,

    // SETTINGS

    assessment,
    assessments,
    assessmentsCounts,
    noCheckins,

    // CHECK POINT

    checkpoint,
    checkpoints,
    checkins,

    router: connectRouter(history),
});

export default createRootReducer;
