import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import Header from '../controllers/header';
import Ask from '../components/ask';

const AdminPass = ({
    user, onOverride, error, success, onLogin, assessments, onIssueRedPass, onFetchByUsername, onReset, fetchingPasses,
}) => {
    // STYLES

    const [css] = useStyletron();

    // STATE

    const [filter, setFilter] = useState();
    const [showRedOnly, setShowRedOnly] = useState(false);
    const [showUserNameDlg, setShowUserNameDlg] = useState(false);
    const [showOverrideDlg, setShowOverrideDlg] = useState(null);
    const [showRedPassDlg, setShowRedPassDlg] = useState(null);
    const [viewingByUsername, setViewingByUsername] = useState(false);
    const [userName, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [directNote, setDirectNote] = useState('');

    const setUserNameEx = (_userName) => {
        let userNameNew = _userName;
        if (!userNameNew.startsWith('sjfc_')) {
            userNameNew = `sjfc_@${userNameNew}`;
        }
        setUserName(userNameNew);
    };

    let filteredAssessments = assessments;
    if (filter && filter.trim() !== '') {
        const lcFilter = filter.toLowerCase();
        filteredAssessments = filteredAssessments.filter((ass) => {
            if (ass.userName.toLowerCase().indexOf(lcFilter) >= 0) {
                return true;
            }
            if (ass.firstName.toLowerCase().indexOf(lcFilter) >= 0) {
                return true;
            }
            if (ass.lastName.toLowerCase().indexOf(lcFilter) >= 0) {
                return true;
            }
            return false;
        });
    }
    if (showRedOnly) {
        filteredAssessments = filteredAssessments.filter((ass) => {
            if (typeof (ass.overriddenBy) !== 'undefined' && showRedOnly) {
                return false;
            }

            return true;
        });
    }
    filteredAssessments = _.sortBy(filteredAssessments, ['created']).reverse();


    // VERIFY

    if (!user) {
        return (
            <>
                <Helmet>
                    <title>SJFC Admin Pass</title>
                </Helmet>

                <Header />

                <div className={css({ margin: '10%', textAlign: 'center' })}>
                    <p>You are not currently logged in. You must log in as a valid and authorized user to proceed.</p>
                    <button className={css({ border: 'solid 1px #0077c8', padding: '5px', color: '#0077c8' })} onClick={onLogin}>Login</button>
                </div>

                <div className={css({ height: '75px' })} />
            </>
        );
    }

    if (user.adminPass !== true) {
        return (
            <>
                <Helmet>
                    <title>SJRC Admin Pass</title>
                </Helmet>

                <Header />

                <div className={css({ margin: '10%', textAlign: 'center' })}>
                    <p>You do not have sufficient admin privileges.</p>
                </div>

                <div className={css({ height: '75px' })} />
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>Manage Red Passes</title>
            </Helmet>

            <Header />

            <div className={css({
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '0',
                marginRight: '0',
                marginTop: '0',
                '@media screen and (min-width: 564px)': {
                    marginLeft: '20%',
                    marginRight: '20%',
                },
            })}
            >
                <h2 className={css({ textTransform: 'uppercase' })}>Manage Passes</h2>

                <div className={css({
                    paddingBottom: '8px', display: 'flex', fontWeight: 'bold', borderBottom: 'solid 1px #000000',
                })}
                >
                    Filter By Banner ID/Name:&nbsp;&nbsp;<input readOnly={viewingByUsername} className={css({ width: '110px' })} onChange={e => setFilter(e.target.value)} />
                    { viewingByUsername !== true && <span>&nbsp;&nbsp;<input checked={showRedOnly} type="checkbox" onChange={() => setShowRedOnly(!showRedOnly)} /> Red Passes Only</span>}
                    <div className={css({ flex: '1', textAlign: 'right' })}>
                        <button onClick={() => setShowUserNameDlg(true)}>View By Banner Id</button>
                        { viewingByUsername && <button className={css({ marginLeft: '10px' })} onClick={() => { onReset(); setViewingByUsername(false); }}>Reset</button>}
                    </div>
                </div>

                <div className={css({ display: 'flex' })}>
                    <table className={css({ width: '100%', borderCollapse: 'collapse', fontSize: '20px' })}>

                        { filteredAssessments.map((assessment, idx) => {
                            let color = assessment.status === 'DENIED' ? '#FF0000' : 'rgb(172, 209, 140)';
                            if (assessment.overridden) {
                                color = 'rgb(172, 209, 140)';
                            }

                            return (
                                <tbody key={assessment.id}>
                                    <tr className={css({ backgroundColor: idx % 2 !== 0 ? '#dfdfdf' : 'transparent' })}>
                                        <td className={css({ color })}>
                                            <i className={assessment.overridden ? 'fas fa-dot-circle' : 'fas fa-square'} />
                                        </td>
                                        <td>&nbsp;</td>
                                        <td className={css({ whiteSpace: 'nowrap' })}>{moment.unix(assessment.created).format('MM/DD/YYYY hh:mm A')}</td>
                                        <td>&nbsp;</td>
                                        <td className={css({ whiteSpace: 'nowrap' })}>{assessment.userName}</td>
                                        <td>&nbsp;</td>
                                        <td className={css({ whiteSpace: 'nowrap' })}>{assessment.firstName}</td>
                                        <td>&nbsp;</td>
                                        <td className={css({ whiteSpace: 'nowrap' })}>{assessment.lastName}</td>
                                        <td>&nbsp;</td>
                                        { viewingByUsername && <td className={css({ whiteSpace: 'nowrap' })}>{assessment.status}</td> }
                                        { viewingByUsername && <td>&nbsp;</td> }
                                        <td className={css({ whiteSpace: 'nowrap' })}>
                                            {(assessment.status === 'APPROVED' || assessment.status === 'NOT_ATTENDING' || assessment.overridden === true) && idx === 0 && (
                                                <span onClick={() => { setViewingByUsername(false); setShowRedPassDlg(assessment); }}><i className={`${css({ cursor: 'pointer', color: '#686868' })} fas fa-square`} title="Issue Red Pass" /></span>
                                            )}
                                            {assessment.status === 'DENIED' && assessment.overridden !== true && (
                                                <span onClick={() => { setViewingByUsername(false); setShowOverrideDlg(assessment); }}><i className={`${css({ cursor: 'pointer', color: '#686868' })} fas fa-dot-circle`} title="Issue Override" /></span>
                                            )}
                                        </td>
                                    </tr>
                                    { (typeof (assessment.note) !== 'undefined' || typeof (assessment.overriddenBy) !== 'undefined') && (
                                        <tr className={css({ backgroundColor: idx % 2 !== 0 ? '#dfdfdf' : 'transparent' })} >
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td colSpan="10" className={css({ fontSize: '12px' })} >
                                                {typeof (assessment.overriddenBy) !== 'undefined' && <span>Overridden By: {assessment.overriddenBy} | </span>}
                                                {assessment.note}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            );
                        }) }
                        { filteredAssessments.length === 0 && fetchingPasses === true && (<tbody><tr><td>Loading Passes...</td></tr></tbody>)}
                        { filteredAssessments.length === 0 && fetchingPasses !== true && viewingByUsername && (
                            <tbody>
                                <tr>
                                    <td>
                                        <p>The user you were searching for ({userName}) was not found. You may enter a pass for that user by providing a first and last name below.</p>
                                        <div>Enter Pass for: <strong>{userName}</strong></div>
                                        <br />
                                        <table className={css({ position: 'relative' })} >
                                            <tbody>
                                                <tr>
                                                    <td>First Name</td>
                                                    <td>Last Name</td>
                                                </tr>
                                                <tr>
                                                    <td><input value={firstName} onChange={e => setFirstName(e.target.value)} /></td>
                                                    <td><input value={lastName} onChange={e => setLastName(e.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">
                                                        Note<br /><input className={css({ width: '100%' })} value={directNote} onChange={e => setDirectNote(e.target.value)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" />
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button
                                                            className={css({ backgroundColor: '#ff0000', color: '#ffffff' })}
                                                            onClick={() => {
                                                                onIssueRedPass({
                                                                    firstName, lastName, role: 'STUDENT', userName: userName.trim(),
                                                                }, `Direct red pass issued ${user.userName}. ${directNote}`);
                                                                setViewingByUsername(false);
                                                            }}
                                                        >
                                                            Create Red Pass
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            className={css({ backgroundColor: 'rgb(172, 209, 140)', color: '#ffffff' })}
                                                            onClick={() => {
                                                                onIssueRedPass({
                                                                    firstName, lastName, role: 'STUDENT', userName: userName.trim(),
                                                                }, `Direct green pass issued by ${user.userName}. ${directNote}`);
                                                                setViewingByUsername(false);
                                                            }}
                                                        >
                                                            Create Green Pass
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>)}
                    </table>

                </div>


                { error !== null && (
                    <p className={css({ color: '#FF0000' })}>
                        {error}
                    </p>
                )}

                { success !== null && (
                    <p className={css({ color: '#00FF00' })}>
                        {success}
                    </p>
                )}

            </div>
            { showUserNameDlg && <Ask type="number" title="Enter Banner ID" isOpen onCancel={() => setShowUserNameDlg(false)} onAnswer={(value) => { setViewingByUsername(true); setShowUserNameDlg(false); setUserNameEx(value); onFetchByUsername(value); }} >Enter Banner ID</Ask> }
            { showOverrideDlg !== null && <Ask title="Override Red Pass" isOpen onCancel={() => setShowOverrideDlg(null)} onAnswer={(note) => { setShowOverrideDlg(null); onOverride(showOverrideDlg, note); }} >Enter Note/Reason</Ask> }
            { showRedPassDlg !== null && <Ask title="Issue Red Pass" isOpen onCancel={() => setShowRedPassDlg(null)} onAnswer={(note) => { setShowRedPassDlg(null); onIssueRedPass(showRedPassDlg, note); }} >Enter Note/Reason</Ask> }
            <div className={css({ height: '75px' })} />
        </>
    );
};

export default AdminPass;
