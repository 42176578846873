import { connect } from 'react-redux';
import AdminPass from '../components/adminPass';
import { overrideAssessment, logRedPass, fetchAssessmentsByUsername, fetchLatestDenied } from '../state/assessment-actions';

const mapStateToProps = state => ({
    user: state.user ? state.user : null,
    error: typeof (state.ui.adminPassError) !== 'undefined' ? state.ui.adminPassError : null,
    success: typeof (state.ui.adminPassSuccess) !== 'undefined' ? state.ui.adminPassSuccess : null,
    assessments: state.assessments,
    fetchingPasses: state.ui.fetchingPasses,
});

const mapDispatchToProps = dispatch => ({
    onLogin: () => {
        window.location.href = '/login.html';
    },
    onOverride: (assessment, note) => {
        dispatch(overrideAssessment(assessment, note !== '' ? note : null));
    },
    onIssueRedPass: (assessment, note) => {
        dispatch(logRedPass(assessment, note));
    },
    onFetchByUsername: (userName) => {
        dispatch(fetchAssessmentsByUsername(userName));
    },
    onReset: () => {
        dispatch(fetchLatestDenied());
    },
});

const AdminPassController = connect(mapStateToProps, mapDispatchToProps)(AdminPass);

export default AdminPassController;
